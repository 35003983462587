import React, { useEffect, useRef } from "react";
import { Link } from 'gatsby'

import * as sideNavStyles from "./sideNav.module.css";

function MenuLink({to, root, location, children}){
  const hash = location.hash
  const pathname = location.pathname
  return (
    <li style={{whiteSpace: "break-spaces"}}><Link style={{color: hash === `#${to}` ? '#bba040' : null}} to={pathname === root ?  `#${to}` : `${root}#${to}`}>{children}</Link></li>
  )
}

export default function DynamicNav(props) {
  const hash = props.location.hash
  const content = props.content

  const aboutItems = content.aboutHeadings.map(
      item => <MenuLink to={item.id} location={props.location} root={"/about"}>{item.text}</MenuLink>
  )
  const statementItems = content.statementsHeadings.map(
    item => <MenuLink to={item.id} location={props.location} root={"/statements"}>{item.text}</MenuLink>
)

  return (
    <nav style={{maxWidth: "25vw"}}>
    <div className={sideNavStyles.sidenav_cont}>
    <Link to="/about"><h4>about</h4></Link>
      <ul className={sideNavStyles.sidenav}>
        <section>
            {aboutItems}
        </section>
      </ul>

      <Link to="/statements"><h4>statements</h4></Link>
      <ul className={sideNavStyles.sidenav}>
        <section>
            {statementItems}
        </section>
      </ul>

      <Link to="/media"><h4>media</h4></Link>
      <ul className={sideNavStyles.sidenav}>
        <section>
        <MenuLink to={"a-self-portrait"} location={props.location} root={"/media"}>a self-portrait</MenuLink>
        <MenuLink to={"scion-installation"} location={props.location} root={"/media"}>scion installation</MenuLink>
        </section>
      </ul>

      </div>
    </nav>
  );
}
