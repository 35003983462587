import React from "react";
import { Link } from 'gatsby'

export default function DynamicContents( { content }) {

  const items = content.map(c => <li><Link to={"#" + c.id}>{c.text}</Link></li>)

  return (
    <section>
        {items}
    </section>
  );
}
