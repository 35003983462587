import DynamicNav from "../components/DynamicNav"
import DynamicContents from "../components/DynamicContents"
import Layout from "../components/layout";

import {myContext} from '../components/PageContext';
import React, {useEffect, useContext} from "react";

import * as sideNavStyles from '../components/sideNav.module.css'
import {Helmet} from "react-helmet";


export default function About(props) {
    const context = useContext(myContext);
    const {html, content} = props.pageContext

    useEffect(() => {
        context.sideNavOn()
    }, []);

    return (
        <>
            <Helmet htmlAttributes={{lang: 'en-uk'}}>
                <meta charSet="utf-8"/>
                <title>Alan Macdonald | About</title>
                <meta name="description" content="About Scottish artist Alan Macdonald."/>
                <meta name="keywords"
                      content="Alan Macdonald, about, paintings by Alan Macdonald, Allan Macdonald, Alan Mcdonald, Allan Mcdonald, Alan MacDonald, artist, painter, painting, paintings, British artists, British painters, British art, Duncan of Jordanstone College of Art, Scottish art, Scottish painters"/>
                <meta name="author" content="Alan Macdonald"/>
                <meta charSet="utf-8"/>
                <meta property="og:title" content='Alan Macdonald'/>
                <meta property="og:description" content="About Scottish artist Alan Macdonald."/>
            </Helmet>
            <Layout>
                <DynamicNav location={props.location} content={content}/>
                <main
                    className={"about"}
                >
                    <h2 style={{marginTop: "2rem"}}>About</h2>

                    <div className={sideNavStyles.sidenav_alt}>
                        <h3>Contents</h3>
                        <ul className={sideNavStyles.sidenav}>

                            <DynamicContents content={content.aboutHeadings}/>
                        </ul>
                    </div>

                    <div dangerouslySetInnerHTML={{__html: html}}></div>


                </main>
            </Layout>
        </>
    );
}